<template>
  <div class="trainingWrap container">
    <header>
      <headerTop></headerTop>
      <navTab></navTab>
    </header>
    <div class="main-content">
       <div class='content'>
          <div class="examList">
            <div class="titleWrap">
              <div class="title" :class="index == chouseIndex?'bottomDiv':''" v-for="(item, index) in btnList" :key="index" @click="chouseBtn(index)">{{item.name}}</div>
            </div>

            <!-- 考试日程 -->
            <ul class='examListbox' v-if="chouseIndex == 0 && recentlyExam.length>0">
              <li class='examItem' :class="{'activeExamItem':index==chooseIndex}" v-for="(item,index) in recentlyExam" @click='jumpExamItemPage(item,index, 1)' :key='index'>
                <div class="left">
                  <div class="status" :class="{'status_1':item.status=='START','status_2':item.status=='END'||item.status=='SUBMITTED'||item.status=='REVIEWED','status_3':item.status=='NOT_START'}">{{item.statusText}}</div>
                  <div class="examName">{{item.paperTitle}}</div>
                </div>
                <div class="time" style="color: #f7a35b;" v-if="item.status=='START'">立即考试</div>
              </li>
            </ul>
            <!-- 成绩中心 -->
            <ul class='examListbox' v-else-if="chouseIndex == 1 && recentlyExam.length>0">
              <li class='examItem' :class="{'activeExamItem':index==chooseIndex}" v-for="(item,index) in recentlyExam" @click='jumpExamItemPage(item,index, 2)' :key='index'>
                <div class="left">
                  <div class="examName">
                    <span class="name">{{item.paperTitle}}</span>
                    <span class="endTime">完成时间：{{item.execEndTime}}</span>
                  </div>
                </div>
                <div class="time" v-if="item.status=='REVIEWED' || item.status=='END'">成绩：<div class="realScore">{{item.realScore}}</div></div>
                <div class="time" v-else>未批阅</div>
              </li>
            </ul>
            <el-empty v-else description="暂无数据"></el-empty>
          </div>
          <div class="studentInfoCard">
            <div class="baseInfo">
              <div class='userPhoto'><img :src="this.userInfo.photoUrl? this.userInfo.photoUrl:require('../../assets/examCenterUser.png')" alt=""></div>
              <div class="info-content">
                Hi,学员<div class="name">{{userName}}</div>
              </div>
            </div>
            <div class='lastExamTime'><!-- 最近考试时间：2022年4月21日 --></div>
            <div class='otherInfo'>单位：{{this.userInfo.gzdw}}</div>
            <div class='otherInfo'>职务：{{this.userInfo.zhiwu}}</div>
            <div class='otherInfo'>电话：{{this.userInfo.lxdh}}</div>
          </div>
       </div>
    </div>
    <footer-com></footer-com>
  </div>
</template>

<script>
import footerCom from '@/components/footer.vue'
import navTab from '@/components/navTab.vue'
import headerTop from '@/components/header.vue'
export default {
  name: '',
  components: {
    footerCom,
    navTab,
    headerTop
  },
  props: {},
  watch: {},
  data () {
    return {
      recentlyExam: '',
      total: '',
      userName: '',
      userInfo: {},
      activeName: 'first',
      chooseIndex: 0,
      btnList: [
        {
          name: '考试日程'
        },
        {
          name: '成绩中心'
        }
      ],
      chouseIndex: 0
    }
  },
  methods: {
    chouseBtn (index) {
      this.chouseIndex = index
    },
    pageJumpLogin () {
      this.$router.push('/login')
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    jumpExamItemPage (item, index, code) {
      if (code == 1) {
        if (item.status == 'START') {
          this.chooseIndex = index
          this.$router.push(`/examItemInfo?examInfoId=${item.examInfoId}&code=start`)
        }
      } else {
        // 批改过才能进入
        if (item.realScore) {
          this.$router.push(`/examItemInfo?id=${item.id}&code=end`)
        }
      }
    },
    // 获取local中用户信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo') ? JSON.parse(localStorage.getItem('studentInfo')) : ''
      console.log(userinfo)
      if (userinfo) {
        let {stuName} = userinfo
        this.userName = stuName
      }
    },
    getLoadStudentExtend () {
      this.$axios.post(this.$apiUrl.loadStudentExtend, {}).then((response) => {
        console.log(response)
        if (response.data.success) {
          let res = response.data
          this.userInfo = res.data
        }
      })
    },
    examQueryPage () {
      let params = {
        pageNo: 1,
        pageSize: 999
      }
      this.$axios.get(this.$apiUrl.examQueryPage, {params}).then(res => {
        if (res.data.success) {
          this.recentlyExam = res.data.data.records
          this.recentlyExam.forEach(item => {
            this.$set(item, 'statusText', this.statuEnum(item.status))
          })
          this.total = res.data.data.total
        }
      })
    },
    statuEnum (status) {
      let sta_enum = {
        'START': '进行中',
        'END': '已结束',
        'NOT_START': '未开始',
        'SUBMITTED': '已提交',
        'REVIEWED': '已批阅',
      }
      return sta_enum[status]
    },
  },
  mounted () {
    this.getUserInfo()
    this.getLoadStudentExtend()
    // 考试列表
    this.examQueryPage()
  },
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
  min-height: 100vh;
  background-color: #fbfbff;

  header {
    background: url("../../assets/image/homeImg/headerBg.png");
    background-size: 100% 100%;
    height: 231px;
  }
}
.main-content {
  width: 1240px;
  padding-bottom: 188px;
  margin: -84px auto 0;
  background-color: transparent;
  .content{
    margin-bottom: 50px;
    width: 1240px;
    display: flex;
    justify-content: space-around;
    .examList{
      width: 868px;
      background: #FFFFFF;
      box-shadow: 0px 6px 30px 1px rgba(0,0,0,0.12);
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      border: 1px solid rgba(0,0,0,0);
      min-height: 596px;
      .titleWrap{
        padding: 0 30px;
        height: 59px;
        border-bottom: 1px solid rgba(112,112,112,0.2);
        display: flex;
        .title{
          cursor: pointer;
          height: 58px;
          width: 88px;
          font-size: 22px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #1C4ECC;
          line-height: 50px;
          margin-right: 20px;

        }
        .bottomDiv{
          border-bottom: 2px solid #1C4ECC;
        }
      }
      .examListbox{
        padding: 20px 0;
        .examItem{
          display: flex;
          width: 100%;
          height: 44px;
          border-radius: 0px 0px 0px 0px;
          /* opacity: 0.26; */
          justify-content: space-between;
          align-items: center;
          color: #000000;
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          cursor: pointer;
          .left{
            display: flex;
            align-items: center;
            .status {
              margin-left: 10px;
              padding: 2px 10px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              border-radius: 4px;
            }
            .status_1 {
              border: 2px solid #FB7500;
              background: #FFEFE1;
              color: #FB7500;
            }
            .status_2 {
              border: 2px solid #0090FF;
              background: #CBE8FF;
              color: #0090FF;
            }
            .status_3 {
              border: 2px solid rgba(0, 0, 0, 0.35);
              background: #DEDEDE;
              color: #000000;
            }
          }
          .examName{
              height: 30px;
              line-height: 30px;
              padding-left: 10px;
              border-left: 3px solid #fff;
              opacity: 1;
              margin-left: 10px;
              .name{
                font-size: 14px;
              }
              .endTime{
                margin-left: 10px;
                font-size: 12px;
              }
            }
            .time{
              padding: 0px 30px;
              opacity: 1;
              display: flex;
              .realScore{
                font-size: 20px;
                font-weight: bold;
                color: #FF0000;
              }
            }
          }
        .activeExamItem{
          background: #E8F0FE;
          .examName{
            border-left: 3px solid #4774DF;
          }
        }
      }
    }
    .studentInfoCard{
      width: 350px;
      padding: 30px;
      background: #FFFFFF;
      box-shadow: 0px 6px 30px 1px rgba(0,0,0,0.12);
      border-radius: 20px 20px 20px 20px;
      opacity: 1;
      border: 1px solid rgba(0,0,0,0);
      .baseInfo{
        display: flex;
        align-items: center;
        .userPhoto{
          width: 137px;
          height: 200px;
          border: 1px solid #707070;
          img{
            width: 135px;
            height: 198px;
          }
        }
        .info-content{
          font-size: 22px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          margin-left: 32px;
        }
      }
      .lastExamTime{
        padding: 20px 0px;
        font-size: 20px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #FF6767;
        border-bottom: 1px dashed #707070
      }
      .otherInfo{
        padding: 20px 0px;
        font-size: 20px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }
    }
  }
}
::v-deep .el-empty{
      text-align: center;
      .el-empty__image{
        width: 200px;
    margin: 0 auto;
}
.el-empty__description{
      font-size: 20px;
    padding-top: 30px;
}
      }
</style>
